<template>
  <b-form-group
    label="Telefon 2"
    label-for="phone2"
  >
    <b-input-group>
      <b-input-group-prepend is-text>
        TR (+9)
      </b-input-group-prepend>
      <cleave
        id="phone2"
        v-model="customer.phone2"
        class="form-control"
        :raw="false"
        :options="options.phone2"
        placeholder="Telefon 2"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'

export default {
  name: 'Phone2',
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    Cleave,
  },
  data() {
    return {
      locale: 'tr',
      options: {
        phone2: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
}
</script>

<style scoped></style>
